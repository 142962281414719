<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :retain-focus="false"
    :value="dialog"
    :max-width="800"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeModal(false)">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="3" lg="6" md="6" sm="6">
              <v-text-field
                label="Nome completo"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.name"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="6" md="6" sm="6">
              <v-text-field
                label="Departamento"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.area"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="6" md="6" sm="6">
              <v-text-field
                label="Email"
                dense
                outlined
                required
                :rules="[required, email]"
                v-model="model.email"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="4" md="4" sm="6">
              <v-text-field
                label="DDD e Telefone"
                dense
                outlined
                v-model="model.phoneNumber"
                v-mask="'(##) ####-####'"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <v-text-field
                label="Ramal"
                dense
                outlined
                v-model="model.phoneExtension"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="4" md="4" sm="6">
              <v-text-field
                label="DDD e Celular"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.mobileNumber"
                v-mask="'(##) #####-####'"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-autocomplete
                label="Tipo"
                dense
                outlined
                required
                :rules="[required]"
                :items="types"
                v-model="model.type"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="b-dialog--actions">
          <BaseButton
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { unmask } from '@/helpers/utils';
import { showMessage } from '@/helpers/messages';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true,
      default: false
    },
    selectedItem: {
      type: Object
    },
    insuranceCompanyDocument: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    types: ['PME', 'Empresarial', 'Ambos'],
    model: {
      id: null,
      insuranceCompanyDocument: null,
      name: null,
      area: null,
      email: null,
      phoneNumber: null,
      phoneExtension: null,
      mobileNumber: null,
      type: null
    }
  }),

  created() {
    if (this.editMode) {
      this.model = this.selectedItem;
    }
  },

  methods: {
    closeModal(search) {
      this.$emit('closeModal', search);
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const params = Object.assign({}, this.model);
      params.phoneNumber = unmask(params.phoneNumber);
      params.mobileNumber = unmask(params.mobileNumber);
      params.insuranceCompanyDocument = this.insuranceCompanyDocument;

      if (this.model.id) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status } = await insuranceCompanyService.createContact(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status } = await insuranceCompanyService.updateContact(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
